import { IRouteInterface } from "./IRouteInterface";

// layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

// Dashboards Pages
const AllDashboards = () =>
  import("../views/Pages/Dashboard/AllDashboards.vue");
const CustomerCPsDashboard = () =>
  import("../views/Pages/Dashboard/CustomerCPsDashboard.vue");
const DevicesDashboard = () =>
  import("../views/Pages/Dashboard/DevicesDashboard.vue");
const PlatformsDashboard = () =>
  import("../views/Pages/Dashboard/PlatformsDashboard.vue");
const RegionsDashboard = () =>
  import("../views/Pages/Dashboard/RegionsDashboard.vue");
const TopEntitiesDashboard = () =>
  import("../views/Pages/Dashboard/TopCustomersDashboard.vue");
const TopGroupsDashboard = () =>
  import("../views/Pages/Dashboard/TopGroupsDashboard.vue");

const TrendsDashboards = () =>
  import("../views/Pages/Dashboard/TrendsDashboards.vue");

export const dashboard: IRouteInterface = {
  path: "/",
  redirect: "/dashboard/all",
  component: PrimaryLayout,
  name: "Dashboard",
  display: true,
  title: "Dashboard",
  icon: "fa fa-house",
  active: false,
  children: [
    {
      path: "/dashboard/all",
      name: "All Dashboards",
      component: AllDashboards,
      display: true,
      title: "All",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
      },
    },
    {
      path: "/dashboard/customers",
      name: "Customers Dashboards",
      component: CustomerCPsDashboard,
      display: true,
      title: "Customers",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
      },
    },
    {
      path: "/dashboard/devices",
      name: "Devices Dashboards",
      component: DevicesDashboard,
      display: true,
      title: "Devices",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
      },
    },
    {
      path: "/dashboard/platforms",
      name: "Platforms Dashboards",
      component: PlatformsDashboard,
      display: true,
      title: "Platforms",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
      },
    },
    {
      path: "/dashboard/regions",
      name: "Region Dashboards",
      component: RegionsDashboard,
      display: true,
      title: "Regions",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
        hideAppBar: true,
      },
    },
    {
      path: "/dashboard/top-entity",
      name: "Top Entities Dashboards",
      component: TopEntitiesDashboard,
      display: true,
      title: "Top Entities",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
        hideAppBar: true,
      },
    },
    {
      path: "/dashboard/top-groups",
      name: "Top Groups Dashboards",
      component: TopGroupsDashboard,
      display: true,
      title: "Top Groups",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
        hideAppBar: true,
      },
    },
    {
      path: "/dashboard/trends",
      name: "Trends Dashboards",
      component: TrendsDashboards,
      display: true,
      title: "Trends Dashboards",
      icon: "fa fa-house",
      active: false,
      meta: {
        groupName: "Dashboard",
        hideAppBar: true,
      },
    },
  ],
};
