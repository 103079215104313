const defaultState = { jwt: null, user: null } as IAuthState;

//check if the jwt exists in the local storage
const jwt = localStorage.getItem("access_token");
const user = localStorage.getItem("user");
//get the details of the user from the backend
if (jwt && user) {
  defaultState.jwt = jwt;
  defaultState.user = JSON.parse(user);
}

import getters from "./authGetters";
import actions from "./authActions";
import mutations from "./authMutations";
import { IAuthState } from "./IAuthInterfaces";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
