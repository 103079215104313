// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

const UserProfilePage = () => import("../views/Pages/Profile/Profile.vue");

export const profile: IRouteInterface = {
  path: "/profile",
  component: PrimaryLayout,
  name: "Profile",
  display: false,
  title: "Profile",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/profile",
      name: "User Profile",
      component: UserProfilePage,
      display: false,
    },
  ],
};
