//Getters
export const USER_GETTER_GET_USERS = "USER_GETTER_GET_USERS";
export const USER_GETTER_ROLES = "USER_GETTER_ROLES";
export const USER_GETTER_PERMISSIONS = "USER_GETTER_PERMISSIONS";
export const USER_GETTER_ORGANISATIONS = "USER_GETTER_ORGANISATIONS";
export const USER_GETTER_SELECTED_USER = "USER_GETTER_SELECTED_USER";

//Mutations
export const USER_MUTATION_SET_USERS = "USER_MUTATION_SET_USERS";
export const USER_MUTATION_SET_ROLES = "USER_MUTATION_SET_ROLES";
export const USER_MUTATION_SET_PERMISSIONS = "USER_MUTATION_SET_PERMISSIONS";
export const USER_MUTATION_SET_ORGANISATIONS =
  "USER_MUTATION_SET_ORGANISATIONS";
export const USER_MUTATIONS_SET_SELECTED_USER =
  "USER_MUTATIONS_SET_SELECTED_USER";

//Actions
export const USER_ACTION_GET_USERS = "USER_ACTION_GET_USERS";
export const USER_ACTION_GET_ROLES = "USER_ACTION_GET_ROLES";
export const USER_ACTION_GET_PERMISSIONS = "USER_ACTION_GET_PERMISSIONS";
export const USER_ACTION_GET_ORGANISATIONS = "USER_ACTION_GET_ORGANISATIONS";
export const USER_ACTION_GET_SELECTED_USER = "USER_ACTION_GET_SELECTED_USER";
