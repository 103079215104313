//All the application constants should be declared here
export const APPLICATION_NAME = "Zero G Wizard";

export const PERMISSIONS_ACCESS = {
  USER: {
    LIST: "ACCESS.USER.LIST",
    ADD: "ACCESS.USER.ADD",
    UPDATE: "ACCESS.USER.UPDATE",
    DELETE: "ACCESS.USER.DELETE",
  },
};

export const KEEP_ALIVE = [
  "list-devices",
  "devices-table",
  "token-replaced-devices",
  "token-issues-devices",
  "test-frame-devices",
  "roaming-devices",
  "recycle-bin-devices",
  "activated-devices",
  "free-devices",
  "expiring-devices",
  "deleted-devices",
  "buy-devices",
  "list-devices",
];
/**
 * Colors used in the Charts set
 */

export const COLOR_1 = "#6F369E";
export const COLOR_2 = "#00A2B2";
export const COLOR_3 = "#FEB008";
export const COLOR_4 = "#FF5C25";
export const COLOR_5 = "#999999";
export const COLOR_6 = "#1D1D1F";

export const COLOR_GROUP_DEVICE_BREAKDOWN_BY_STATUS = {
  color: [COLOR_1, COLOR_2, COLOR_3, COLOR_4, COLOR_5, COLOR_6],
  labels: [
    "Activated",
    "Ready",
    "Free Trial",
    "Test Frames",
    "Token Issues",
    "Deleted",
  ],
};

export const COLOR_PLATFORMS = COLOR_2;
export const REGISTERED_COLOR = COLOR_1;
export const ACTIVATED_COLOR = COLOR_2;

export const COLOR_GROUP_ENTITY_BREAKDOWN_BY_TYPE = {
  color: [COLOR_1, COLOR_2, COLOR_4],
  labels: ["Customers", "Channel Partners", "Buy"],
};

export const COLOR_GROUP_DEVICE_BREAKDOWN_BY_ENTITY_TYPE = {
  color: [COLOR_1, COLOR_2, COLOR_4, COLOR_5],
  labels: ["Customers", "Channel Partners", "Buy", "Needs Confirmation"],
};

export const COLOR_GROUP_ACTIVATED_REGISTRED_DEVICES = {
  color: [COLOR_1, COLOR_2],
  labels: ["Registered", "Active"],
};
