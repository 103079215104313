import { IFrontendInterface } from "./IFrontendInterface";

export default {
  getFrontendConfig(state: IFrontendInterface) {
    return state;
  },
  showUniversalLoader(state: IFrontendInterface) {
    return state.showUniversalLoader;
  },
};
