import axios from "axios";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  User,
} from "firebase/auth";
import { ActionContext } from "vuex";
import {
  AUTH_ACTION_LOGIN_FROM_BACKEND,
  AUTH_ACTION_LOGIN_REDIRECT_CHECK,
  AUTH_ACTION_LOGIN_WITH_FIREBASE,
  AUTH_ACTION_LOGOUT_FROM_BACKEND,
  AUTH_ACTION_LOGOUT_FROM_FIREBASE,
} from "./authTypes";
import { IAuthState, IDecodedJWT, IFirebaseLoginType } from "./IAuthInterfaces";

export default {
  [AUTH_ACTION_LOGIN_WITH_FIREBASE]: async (
    context: ActionContext<IAuthState, undefined>,
    {
      type,
      email,
      password,
    }: { type: IFirebaseLoginType; email?: string; password?: string }
  ): Promise<{
    status: "SUCCESS" | "ERROR";
    user?: User;
    message?: string;
    firebaseToken?: string;
  }> => {
    const firebaseAuth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    const response: {
      firebaseToken?: string;
      user?: User;
    } = {};
    let error = null;
    switch (type) {
      case IFirebaseLoginType.EMAIL_PASSWORD: {
        const userCredential = await signInWithEmailAndPassword(
          firebaseAuth,
          email as string,
          password as string
        ).catch((err) => {
          error = err.message as string;
        });
        if (!userCredential) {
          break;
        }
        response.user = userCredential.user;
        response.firebaseToken = await userCredential.user.getIdToken();
        break;
      }
      case IFirebaseLoginType.GOOGLE_POPUP: {
        const userCredential = await signInWithPopup(
          firebaseAuth,
          provider
        ).catch((err) => {
          error = err.message as string;
        });
        if (!userCredential) {
          break;
        }
        response.user = userCredential.user;
        response.firebaseToken = await userCredential.user.getIdToken();
        break;
      }
      case IFirebaseLoginType.GOOGLE_REDIRECT:
        signInWithRedirect(firebaseAuth, provider);
        break;
      default:
        error = "The login type is not supported";
    }

    if (error) {
      return {
        status: "ERROR",
        message: error,
      };
    }

    return {
      status: "SUCCESS",
      ...response,
    };
  },
  [AUTH_ACTION_LOGIN_REDIRECT_CHECK]: async (): Promise<{
    status: "SUCCESS" | "ERROR";
    firebaseToken?: string;
    user?: User;
    message?: string;
  }> => {
    const response: {
      firebaseToken?: string;
      user?: User;
    } = {};
    let error = null;
    const userCredential = await getRedirectResult(getAuth()).catch((err) => {
      error = err.message as string;
    });

    if (!userCredential) {
      throw {
        message:
          "The user should not be in this page. Redirecting to login page",
      };
    }

    response.user = userCredential.user;
    response.firebaseToken = await userCredential.user.getIdToken();

    if (error) {
      return {
        status: "ERROR",
        message: error,
      };
    }

    return {
      status: "SUCCESS",
      ...response,
    };
  },
  [AUTH_ACTION_LOGOUT_FROM_FIREBASE]: async () => {},
  [AUTH_ACTION_LOGIN_FROM_BACKEND]: async (
    context: ActionContext<IAuthState, undefined>,
    { firebaseToken }: { firebaseToken: string }
  ): Promise<{
    status: "SUCCESS" | "ERROR";
    backendToken?: string;
    user?: IDecodedJWT;
    message?: string;
  }> => {
    const response: {
      backendToken?: string;
      user?: IDecodedJWT;
    } = {};
    let error = null;
    //send the firebase jwt to the backend.
    const {
      data: { token },
    } = await axios
      .post<{ token: string }>("api/auth/issueJWT", {
        firebaseToken,
      })
      .catch((err) => {
        error = err.message as string;
        return { data: { token: null } };
      });

    if (token) {
      context.commit("setJWT", { jwt: token });
      //store the jwt in the local storage
      localStorage.setItem("access_token", token);
      //send the jwt back to verify and get the user datails.
      const { data: user } = await axios
        .get("api/auth/user-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          error = err.message as string;
          return { data: null };
        });
      if (user) {
        //store the user datails as well
        context.commit("setUserDetails", { user });
        localStorage.setItem("user", JSON.stringify(user));
      }
    }

    if (error) {
      return {
        status: "ERROR",
        message: error,
      };
    }

    return {
      status: "SUCCESS",
      ...response,
    };
  },
  [AUTH_ACTION_LOGOUT_FROM_BACKEND]: (
    context: ActionContext<IAuthState, undefined>
  ) => {
    //The token is forgottten by the frontend so that the user is virtually logged out
    context.commit("unsetJWT");
    //also remove from the local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    context.commit("removeUserDetails");
    return {
      status: "SUCCESS",
      message: "Successfully Loggged Out!",
    };
  },
};
