//Authentication start
const LoginPage = () => import("../views/Pages/Authentication/Login.vue");

export const login = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    display: false,
  },
  {
    path: "/login-callback",
    name: "Login Callback",
    component: LoginPage,
    display: false,
  },
];
