import { AUTH_GETTER_JWT, AUTH_GETTER_USER_DETAILS } from "./authTypes";
import { IAuthState } from "./IAuthInterfaces";

export default {
  [AUTH_GETTER_USER_DETAILS](state: IAuthState) {
    return state.user;
  },
  [AUTH_GETTER_JWT](state: IAuthState) {
    return state.jwt;
  },
};
