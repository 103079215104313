import { ActionContext } from "vuex";
import {
  getActivatedDevices,
  getBuyDevices,
  getDeletedDevices,
  getDeviceDetails,
  getDeviceList,
  getDevicesOntestFrames,
  getExpiringDevices,
  getFreeDevices,
  getRecycledDevices,
  getRoamingDevices,
  getTokenIssueDevices,
  getTokenReplacedDevices,
} from "../../../apis/device.apis";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import {
  DEVICE_ACTION_GET_ACTIVATED_DEVICE,
  DEVICE_ACTION_GET_BUY_DEVICE,
  DEVICE_ACTION_GET_DELETED_DEVICE,
  DEVICE_ACTION_GET_DEVICE,
  DEVICE_ACTION_GET_DEVICE_DETAILS,
  DEVICE_ACTION_GET_EXPIRING_DEVICE,
  DEVICE_ACTION_GET_FREE_DEVICE,
  DEVICE_ACTION_GET_RECYCLED_DEVICE,
  DEVICE_ACTION_GET_ROAMING_DEVICE,
  DEVICE_ACTION_GET_TEST_FRAME_DEVICE,
  DEVICE_ACTION_GET_TOKEN_ISSUE_DEVICE,
  DEVICE_ACTION_GET_TOKEN_REPLACED_DEVICE,
  DEVICE_MUTATION_SET_DEVICE,
  DEVICE_MUTATION_SET_DEVICE_DETAILS,
} from "./deviceTypes";
import { IDeviceInterface } from "./IDeviceInterface";

export default {
  [DEVICE_ACTION_GET_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getDeviceList(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_ACTIVATED_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getActivatedDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_TEST_FRAME_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getDevicesOntestFrames(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_EXPIRING_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getExpiringDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_BUY_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getBuyDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_FREE_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getFreeDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_TOKEN_ISSUE_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getTokenIssueDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_DELETED_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getDeletedDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_RECYCLED_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getRecycledDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_ROAMING_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getRoamingDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_DEVICE_DETAILS]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    sfDeviceId: string
  ) => {
    //Get the Users and store it
    try {
      const deviceDetails = await getDeviceDetails(sfDeviceId);
      context.commit(DEVICE_MUTATION_SET_DEVICE_DETAILS, deviceDetails);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_ACTION_GET_TOKEN_REPLACED_DEVICE]: async (
    context: ActionContext<IDeviceInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const devices = await getTokenReplacedDevices(params);
      context.commit(DEVICE_MUTATION_SET_DEVICE, devices);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
