//Getters gets the data from the local store
export const DEVICE_GROUPS_GETTER_GET_GROUPS =
  "DEVICE_GROUPS_GETTER_GET_GROUPS";
export const DEVICE_GROUPS_GETTER_GET_GROUP_DETAILS =
  "DEVICE_GROUPS_GETTER_GET_GROUP_DETAILS";
export const DEVICE_GROUPS_GETTER_GET_DIRECT_CHILDREN_HIERARCHY =
  "DEVICE_GROUPS_GETTER_GET_DIRECT_CHILDREN_HIERARCHY";

//Mutations - Mutates the data in the local store
export const DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS =
  "DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS";
export const DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS =
  "DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS";
export const DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY =
  "DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY";

//Actions - Takes actions with the server
export const DEVICE_GROUPS_ACTION_GET_DEVICE_GROUPS =
  "DEVICE_GROUPS_ACTION_GET_DEVICE_GROUPS";
export const DEVICE_GROUPS_ACTION_GET_DEVICE_GROUP_DETALS =
  "DEVICE_GROUPS_ACTION_GET_DEVICE_GROUP_DETALS";
export const DEVICE_GROUPS_ACTION_GET_DIRECT_CHILDREN_HIERARCHY =
  "DEVICE_GROUPS_ACTION_GET_DIRECT_CHILDREN_HIERARCHY";
