import {
  IPlatformInterface,
  IPlatformStoreInterface,
} from "./IPlatformInterface";
import {
  PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS,
  PLATFORM_MUTATION_SET_PLATFORMS,
  PLATFORM_MUTATION_SET_SELECTED_PLATFORM,
} from "./platformTypes";

export default {
  [PLATFORM_MUTATION_SET_PLATFORMS](
    state: IPlatformStoreInterface,
    platforms: [IPlatformInterface[], number]
  ) {
    state.platforms = platforms;
  },
  [PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS](
    state: IPlatformStoreInterface,
    availablePlatformUrls: string[]
  ) {
    state.availablePlatformUrls = availablePlatformUrls;
  },
  [PLATFORM_MUTATION_SET_SELECTED_PLATFORM](
    state: IPlatformStoreInterface,
    selectedPlatform: IPlatformInterface
  ) {
    state.selectedPlatform = selectedPlatform;
  },
};
