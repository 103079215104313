// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

import { PERMISSIONS_ACCESS } from "../constants";

import { allChildPermissions } from "../helpers/permission.helpers";

//Suggestions Page
const EntitySuggestionsPage = () =>
  import("../views/Pages/Suggestions/EntitySuggestionsSetup.vue");

export const suggestions: IRouteInterface = {
  path: "/suggestions",
  component: PrimaryLayout,
  name: "Suggestions",
  display: true,
  title: "Suggestions",
  icon: "fa fa-wrench",
  active: false,
  children: [
    {
      path: "/sugestions/entity-suggestion",
      name: "Entity Suggestions",
      component: EntitySuggestionsPage,
      display: true,
      title: "Entity Suggestions",
      icon: "fa fa-user",
      meta: {
        groupName: "Suggestions",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
  ],
};
