// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

import { PERMISSIONS_ACCESS } from "../constants";

import { allChildPermissions } from "../helpers/permission.helpers";

//Authentication pages
const ListUsersPage = () => import("../views/Pages/Admin/User/ListUsers.vue");
const AddUserPage = () => import("../views/Pages/Admin/User/AddUser.vue");
const UserDetailPage = () => import("../views/Pages/Admin/User/UserDetail.vue");
const ListRolesPage = () => import("../views/Pages/Admin/Role/ListRoles.vue");
const AddRolePage = () => import("../views/Pages/Admin/Role/AddRole.vue");
const RoleDetailPage = () => import("../views/Pages/Admin/Role/RoleDetail.vue");
const ListPermissionsPage = () =>
  import("../views/Pages/Admin/Permission/ListPermissions.vue");
const AddPermissionPage = () =>
  import("../views/Pages/Admin/Permission/AddPermission.vue");
const PermissionDetailPage = () =>
  import("../views/Pages/Admin/Permission/PermissionDetail.vue");

export const admin: IRouteInterface = {
  path: "/access",
  component: PrimaryLayout,
  name: "Access Management",
  display: true,
  title: "Access Management",
  icon: "fa fa-wrench",
  active: false,
  children: [
    {
      path: "/access/users",
      name: "List Users",
      component: ListUsersPage,
      display: true,
      title: "Users",
      icon: "fa fa-user",
      meta: {
        groupName: "Access Management",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/access/users/add",
      name: "Add User",
      component: AddUserPage,
      display: false,
      title: "Add User",
      icon: "fa fa-user",
      meta: {
        groupName: "Access Management",
      },
    },
    {
      path: "/access/users/:email",
      name: "User Details",
      component: UserDetailPage,
      display: false,
      title: "User Details",
      icon: "fa fa-user",
      meta: {
        groupName: "Access Management",
      },
    },
    {
      path: "/access/roles",
      name: "List Roles",
      component: ListRolesPage,
      display: true,
      title: "Roles",
      icon: "fa fa-id-card",
      meta: {
        groupName: "Access Management",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/access/roles/add",
      name: "Add Role",
      component: AddRolePage,
      display: false,
      title: "Add Role",
      icon: "fa fa-user",
      meta: {
        groupName: "Access Management",
      },
    },
    {
      path: "/access/roles/:roleId",
      name: "Role Details",
      component: RoleDetailPage,
      display: false,
      title: "Role Details",
      icon: "fa fa-id-card",
      meta: {
        groupName: "Access Management",
      },
    },
    {
      path: "/access/permissions",
      name: "List Permissions",
      component: ListPermissionsPage,
      display: true,
      title: "Permissions",
      icon: "fa fa-bolt",
      meta: {
        groupName: "Access Management",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/access/permissions/add",
      name: "Add Permission",
      component: AddPermissionPage,
      display: false,
      title: "Add Permission",
      icon: "fa fa-bolt",
      meta: {
        groupName: "Access Management",
      },
    },
    {
      path: "/access/permissions/:permissionId",
      name: "Permission Details",
      component: PermissionDetailPage,
      display: false,
      title: "Permission Details",
      icon: "fa fa-bolt",
      meta: {
        groupName: "Access Management",
      },
    },
  ],
};
