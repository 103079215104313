import axios from "../axios";
import { IDeviceInterface } from "../store/modules/device/IDeviceInterface";
import { IEntityInterface } from "../store/modules/entity/IEntityInterface";

export interface IEntityStatsInterface {
  countOfDevices: number;
  countOfActiveDevices: number;
  countOfRoamableDevices: number;
  countOfRoamingDevices: number;
  countOfDeletedDevices: number;
  averageTimeTakenToActivate: string;
  averageTimeTakenByInactive: string;
  platforms: {
    platformId: string;
    platformName: string;
    baseUrl: string;
    calcActivatedDevices: number;
    calcRegisteredDevices: number;
  }[];
}

export interface IImportEntitiesInterface {
  add: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[];
  update: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[];
  ignore: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[];
  error: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[];
  soracom: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[];
}

export interface IEntityLogsInterface {
  id: string;
  thxEntityId: string;
  calcRegistered: number;
  calcNotActivated: number;
  calcActivated: number;
  calcTestFrame: number;
  calcTestFrameValid: number;
  calcTestFrameOffContract: number;
  calcTokenValid: number;
  calcTokenInvalid: number;
  calcTokenTransferDevices: number;
  calcOffContract: number;
  calcTokenIssue: number;
  calcRegisteredDevicesAU: number;
  calcActivatedDevicesAU: number;
  calcTokenValidAU: number;
  calcRegisteredDevicesNZ: number;
  calcActivatedDevicesNZ: number;
  calcTokenValidNZ: number;
  calcRegisteredDevicesHK: number;
  calcActivatedDevicesHK: number;
  calcTokenValidHK: number;
  createdAt: string;
}

export async function getEntitiesList(
  params: any
): Promise<[IEntityInterface[], number]> {
  const { data: entities } = await axios.get<[IEntityInterface[], number]>(
    "/entity-manager/",
    {
      params,
    }
  );
  return entities;
}

export async function getEntityDetails(thxEntityId: string) {
  const { data: enitityDetails } = await axios.get<{
    sfEntitieDetails: IEntityInterface[];
  }>(`/entity-manager/${thxEntityId}`);
  return enitityDetails;
}

export async function getEntityStats(thxEntityId: string) {
  const { data: entityStats } = await axios.get<IEntityStatsInterface>(
    `/entity-manager/${thxEntityId}/stats`
  );
  return entityStats;
}

export async function getEntityDevices(thxEntityId: string, params: any) {
  const { data: entityDevices } = await axios.get<[IDeviceInterface[], number]>(
    `/entity-manager/${thxEntityId}/devices`,
    { params }
  );
  return entityDevices;
}

export async function getAvailableDeviceGroups(params: { [key: string]: any }) {
  const { data: availableDeviceGroups } = await axios.get<{
    sfEntitieDetails: IEntityInterface[];
  }>(`/group-manager/device-groups-to-link`, { params });
  return availableDeviceGroups;
}

export async function getAvailableDeviceTypes(params: { [key: string]: any }) {
  const { data: availableDeviceTypes } = await axios.get<{
    sfEntitieDetails: IEntityInterface[];
  }>(`/device-type-manager/device-types-to-link/`, { params });
  return availableDeviceTypes;
}

export async function getThxEntityLogs(
  thxEntityId: string,
  limit?: number,
  page?: number
) {
  const { data: entityLogs } = await axios.get<
    [IEntityLogsInterface[], number]
  >(`/entity-manager/${thxEntityId}/logs`, { params: { limit, page } });
  return entityLogs;
}

export async function isEntityNameAvailable(name: string) {
  const { data: availability } = await axios.get<{
    entityNameAvailabile: Boolean;
    foundEntity: { [key: string]: any };
  }>("/entity-manager/entity-name-availability", { params: { name } });
  return availability;
}

export async function createNewEntity(
  name: string,
  type: string,
  groups: string[],
  deviceTypes: string[]
) {
  const { data: createdEntity } = await axios.post<IEntityInterface>(
    "/entity-manager",
    { name, type, groups, deviceTypes }
  );
  return createdEntity;
}

export async function updateExistingEntity(
  entityId: string,
  props: {
    name?: string;
    type?: string;
    groups?: string[];
    deviceTypes?: string[];
  }
) {
  const { data: updatedEntity } = await axios.patch<IEntityInterface>(
    `/entity-manager/${entityId}`,
    props
  );
  return updatedEntity;
}

export async function deleteEntity(entityId: string) {
  const { data: deletedEntity } = await axios.delete<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>(`/entity-manager/${entityId}`);
  return deletedEntity;
}

export async function parseImportEntitiesCSV(
  file: any
): Promise<IImportEntitiesInterface> {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await axios.post<IImportEntitiesInterface>(
    `/entity-manager/parse-entity-import-csv`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
}

export async function addEntitiesFromImportList(
  entitiesToAdd: {
    thxName: string;
    thxPlanType: string;
    thxCurrency: string;
    thxCustomerCode: string;
    sfDeviceGroupId: string;
    sfDeviceGroupName: string;
  }[]
) {
  const { data: addEntityReponse } = await axios.post<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>("/entity-manager/add-entities-from-import-list", entitiesToAdd);
  return addEntityReponse;
}
