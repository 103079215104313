import axios from "../axios";
import { IContractInterface } from "../store/modules/contract/IContractInterface";

export async function getContractList(
  params: any
): Promise<[IContractInterface[], number]> {
  const { data: contracts } = await axios.get<[IContractInterface[], number]>(
    "/contract-manager/",
    {
      params,
    }
  );
  return contracts;
}

export async function getExpiringContractsList(
  params: any
): Promise<[IContractInterface[], number]> {
  const { data: contracts } = await axios.get<[IContractInterface[], number]>(
    "/contract-manager/expiring",
    {
      params,
    }
  );
  return contracts;
}

export async function getContractDetails(sfContractId: string) {
  const { data: contractDetails } = await axios.get<IContractInterface>(
    `/contract-manager/${sfContractId}`
  );
  return contractDetails;
}
