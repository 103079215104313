import { IFrontendInterface } from "./IFrontendInterface";

export default {
  updateFrontendConfig(
    state: IFrontendInterface,
    props: { [key: string]: any }
  ) {
    Object.keys(props).forEach((key) => {
      state[key] = props[key];
    });
  },
  showUniversalLoader(state: IFrontendInterface, newPreloaderState: boolean) {
    state.showUniversalLoader = newPreloaderState;
  },
};
