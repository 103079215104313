import axios from "axios";
import {
  AUTH_ACTION_LOGOUT_FROM_BACKEND,
  AUTH_GETTER_JWT,
} from "./store/modules/auth/authTypes";
import Store from "./store/store";
import Vue from "vue";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = "/api/";
    if (!config.headers) {
      config["headers"] = {};
    }
    config.headers[
      "Authorization"
    ] = `Bearer ${Store.getters[AUTH_GETTER_JWT]}`;

    return config;
  },
  (error) => {
    // if the error is 400 or 404 check for the erors
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (value: any) => value,
  (err) => {
    if (axios.isAxiosError(err) && err.response) {
      const { statusCode, message, error } = err.response.data as {
        statusCode: number;
        message: string;
        error: string;
      };
      if (
        [400, 404].includes(statusCode) &&
        ["Bearer Token not valid", "Bearer Token Not found"].includes(message)
      ) {
        //Clear the session and send to the login page
        Store.dispatch(AUTH_ACTION_LOGOUT_FROM_BACKEND);
        Vue.swal({
          toast: true,
          titleText: "User not logged in.",
          backdrop: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3500,
          icon: "error",
          text: `Please login to continue using the application.`,
          showCloseButton: false,
        });

        //send the user back to the login page
        window.location.href = "/#/login";
      } else if (statusCode === 404) {
        Vue.swal({
          toast: false,
          titleText: error,
          backdrop: true,
          position: "center",
          showConfirmButton: false,
          timer: 3500,
          icon: "error",
          text: message,
          showCloseButton: false,
        });
        //send to the 404 page TODO
      }
    }
    Promise.reject(err);
  }
);

export default axiosInstance;
