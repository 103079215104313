//Interface
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

//Device Page
const ListDeviceGroups = () =>
  import("../views/Pages/DeviceGroups/ListDeviceGroups.vue");
const DeviceGroupDetailsPage = () =>
  import("../views/Pages/DeviceGroups/DeviceGroupDetails.vue");

export const deviceGroups: IRouteInterface = {
  path: "/device-groups",
  component: PrimaryLayout,
  name: "DeviceGroups",
  display: true,
  title: "DeviceGroups",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/device-groups/all",
      name: "List All Device Groups",
      component: ListDeviceGroups,
      display: true,
      title: "List All Device Groups",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "DeviceGroups",
        hideAppBar: true,
      },
    },
    {
      path: "/device-groups/:sfDeviceGroupId",
      name: "Device Group Details",
      component: DeviceGroupDetailsPage,
      display: false,
      title: "Device Group Details",
      icon: "fa fa-user",
      meta: {
        groupName: "DeviceGroups",
        hideAppBar: true,
      },
    },
  ],
};
