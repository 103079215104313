import axios from "../axios";
import {
  IUpdateUserDetailsInterface,
  IUserInterface,
} from "../store/modules/user/IUserStoreInterface";
export async function getUsers(
  params: any
): Promise<[IUserInterface[], number]> {
  const { data: users } = await axios.get<[IUserInterface[], number]>(
    "/user/",
    {
      params,
    }
  );
  return users;
}

export async function createUser(params: any): Promise<IUserInterface> {
  const { data: newUser } = await axios.post<IUserInterface>("/user/", params);

  return newUser;
}

export async function updateUser(
  userEmail: string,
  newDetails: IUpdateUserDetailsInterface
): Promise<IUserInterface> {
  const { data: updatedUser } = await axios.patch<IUserInterface>(
    `/user/${userEmail}`,
    newDetails
  );
  return updatedUser;
}

export async function updateUserPassword(
  userEmail: string,
  newPassword: string
): Promise<{
  status: "SUCCESS" | "ERROR";
  message: string;
}> {
  const { data: updatedUser } = await axios.patch<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>(`/user/${userEmail}/password`, { newPassword });
  return updatedUser;
}

export async function deleteUser(userEmail: string): Promise<{
  status: "SUCCESS" | "ERROR";
  message: string;
}> {
  const { data } = await axios.delete<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>(`/user/${userEmail}`);
  return data;
}
