// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

import { PERMISSIONS_ACCESS } from "../constants";

import { allChildPermissions } from "../helpers/permission.helpers";

//Entitiy Pages
const EntitiesPage = () => import("../views/Pages/Entities/ListEntities.vue");
const EntityDetailsPage = () =>
  import("../views/Pages/Entities/EntityDetails.vue");
const ImportEntitiesPage = () =>
  import("../views/Pages/Entities/ImportEntities.vue");

export const entities: IRouteInterface = {
  path: "/entity",
  component: PrimaryLayout,
  name: "Entitis",
  display: true,
  title: "Entities",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/entity/all",
      name: "List All Entities",
      component: EntitiesPage,
      display: true,
      title: "List All Entities",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Entities",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
    {
      path: "/entity/import",
      name: "Import Entities",
      component: ImportEntitiesPage,
      display: true,
      title: "Import Entities",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Entities",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
    {
      path: "/entity/:thxEntityId",
      name: "Entity Details",
      component: EntityDetailsPage,
      display: false,
      title: "Entity Details",
      icon: "fa fa-user",
      meta: {
        groupName: "Entities",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
  ],
};
