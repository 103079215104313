/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import VueSweetalert2 from "vue-sweetalert2";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { initializeApp } from "firebase/app";
import DashboardPlugin from "./plugins/dashboard-plugin";
import store from "./store/store";

import ECharts from "vue-echarts";

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueSweetalert2);

Vue.component("v-chart", ECharts);

const firebaseConfig = {
  apiKey: "AIzaSyCvSlhdXJX4iB2CVaFpjhsste5AZ0XPGqo",
  authDomain: "zero-g-wizard.firebaseapp.com",
  projectId: "zero-g-wizard",
  storageBucket: "zero-g-wizard.appspot.com",
  messagingSenderId: "501192128809",
  appId: "1:501192128809:web:a6348f782c86c494235f38",
  measurementId: "G-LXSZNP18WT",
};

initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
