import { IUserStoreInterface } from "./IUserStoreInterface";
import {
  USER_GETTER_ORGANISATIONS,
  USER_GETTER_PERMISSIONS,
  USER_GETTER_ROLES,
  USER_GETTER_GET_USERS,
  USER_GETTER_SELECTED_USER,
} from "./userTypes";

export default {
  [USER_GETTER_GET_USERS](state: IUserStoreInterface) {
    return state.users;
  },
  [USER_GETTER_ROLES](state: IUserStoreInterface) {
    return state.roles;
  },
  [USER_GETTER_PERMISSIONS](state: IUserStoreInterface) {
    return state.permissions;
  },
  [USER_GETTER_ORGANISATIONS](state: IUserStoreInterface) {
    return state.organisations;
  },

  [USER_GETTER_SELECTED_USER](state: IUserStoreInterface) {
    return state.selectedUser;
  },
};
