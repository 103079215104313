//Getters
export const DEVICE_GETTER_GET_DEVICE = "DEVICE_GETTER_GET_DEVICE";
export const DEVICE_GETTER_GET_DEVICE_DETAILS =
  "DEVICE_GETTER_GET_DEVICE_DETAILS";
export const DEVICE_ACTION_GET_TEST_FRAME_DEVICE =
  "DEVICE_ACTION_GET_TEST_FRAME_DEVICE";
export const DEVICE_ACTION_GET_EXPIRING_DEVICE =
  "DEVICE_ACTION_GET_EXPIRING_DEVICE";
export const DEVICE_ACTION_GET_BUY_DEVICE = "DEVICE_ACTION_GET_BUY_DEVICE";
export const DEVICE_ACTION_GET_FREE_DEVICE = "DEVICE_ACTION_GET_FREE_DEVICE";
export const DEVICE_ACTION_GET_ACTIVATED_DEVICE =
  "DEVICE_ACTION_GET_ACTIVATED_DEVICE";
export const DEVICE_ACTION_GET_TOKEN_REPLACED_DEVICE =
  "DEVICE_ACTION_GET_TOKEN_REPLACED_DEVICE";
export const DEVICE_ACTION_GET_TOKEN_ISSUE_DEVICE =
  "DEVICE_ACTION_GET_TOKEN_ISSUE_DEVICE";
export const DEVICE_ACTION_GET_DELETED_DEVICE =
  "DEVICE_ACTION_GET_DELETED_DEVICE";
export const DEVICE_ACTION_GET_RECYCLED_DEVICE =
  "DEVICE_ACTION_GET_RECYCLED_DEVICE";
export const DEVICE_ACTION_GET_ROAMING_DEVICE =
  "DEVICE_ACTION_GET_ROAMING_DEVICE";

//Mutations
export const DEVICE_MUTATION_SET_DEVICE = "DEVICE_MUTATION_SET_DEVICE";
export const DEVICE_MUTATION_SET_DEVICE_DETAILS =
  "DEVICE_MUTATION_SET_DEVICE_DETAILS";

//Actions
export const DEVICE_ACTION_GET_DEVICE = "DEVICE_ACTION_GET_DEVICE";
export const DEVICE_ACTION_GET_DEVICE_DETAILS =
  "DEVICE_ACTION_GET_DEVICE_DETAILS";
