import { flattenObjectHybrid } from "../../../helpers/helpers";
import {
  DEVICE_MUTATION_SET_DEVICE,
  DEVICE_MUTATION_SET_DEVICE_DETAILS,
} from "./deviceTypes";
import { IDeviceInterface, IDeviceStoreInterface } from "./IDeviceInterface";

export default {
  [DEVICE_MUTATION_SET_DEVICE](
    state: IDeviceStoreInterface,
    devices: [IDeviceInterface[], number]
  ) {
    //flatten and store the devices.

    state.devices = [
      devices[0].map((d) => flattenObjectHybrid(d) as any),
      devices[1],
    ];
  },
  [DEVICE_MUTATION_SET_DEVICE_DETAILS](
    state: IDeviceStoreInterface,
    selectedDeviceDetails: IDeviceInterface
  ) {
    state.selectedDeviceDetails = selectedDeviceDetails;
  },
};
