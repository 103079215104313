//Getters
export const ENTITY_GETTERS_GET_ENTITIES = "ENTITY_GETTERS_GET_ENTITIES";
export const ENTITY_GETTERS_GET_ENTITY_DETAILS =
  "ENTITY_GETTERS_GET_ENTITY_DETAILS";
export const ENTITY_GETTERS_GET_AVAILABLE_DEVICE_GROUPS =
  "ENTITY_GETTERS_GET_AVAILABLE_DEVICE_GROUPS";
export const ENTITY_GETTERS_GET_AVAILABLE_DEVICE_TYPES =
  "ENTITY_GETTERS_GET_AVAILABLE_DEVICE_TYPES";

//Mutations
export const ENTTY_MUTATIONS_SET_ENTITIES = "ENTTY_MUTATIONS_SET_ENTITIES";
export const ENTITY_MUTATIONS_SET_ENTITY_DETAILS =
  "ENTITY_MUTATIONS_SET_ENTITY_DETAILS";
export const ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS =
  "ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS";
export const ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES =
  "ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES";

//Actions
export const ENTITY_ACTIONS_GET_ENTITIES = "ENTITY_ACTIONS_GET_ENTITIES";
export const ENTITY_ACTIONS_GET_ENTITY_DETAILS =
  "ENTITY_ACTIONS_GET_ENTITY_DETAILS";
export const ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_GROUPS =
  "ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_GROUPS";
export const ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_TYPES =
  "ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_TYPES";
