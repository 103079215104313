import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import Store from "../store/store";
import { AUTH_GETTER_USER_DETAILS } from "../store/modules/auth/authTypes";
import { canUserAccessLoose } from "../helpers/userAccess";
import { IUserInterface } from "../store/modules/user/IUserStoreInterface";
Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const loginPages = ["Login", "Login Callback"];
  if (to.name && loginPages.includes(to.name)) return next();
  //Check if the user is logged in
  const userDetails = Store.getters[AUTH_GETTER_USER_DETAILS] as IUserInterface;
  if (!userDetails) {
    Vue.swal({
      toast: true,
      titleText: "User not logged in.",
      backdrop: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3500,
      icon: "info",
      text: `Please login to continue using the application.`,
      showCloseButton: false,
    });
    return next({ name: "Login" });
  }

  if (to && to.meta && to.meta.permission) {
    if (canUserAccessLoose(to.meta.permission, userDetails)) {
      next();
    } else {
      Vue.swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        icon: "warning",
        title: "Error",
        text: `You do not have required permission for this action. Please contact administrator if this is a mistake`,
        showCloseButton: false,
      });
      return false;
    }
  }
  next();
});

// router.afterEach((to, from, failure) => {
//   if (!failure) sendToAnalytics(to.fullPath);
// });

export default router;
