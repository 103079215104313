import { ActionContext } from "vuex";
import {
  getAvailablePlatformUrls,
  getPlatformDetails,
  getPlatformsList,
} from "../../../apis/platforms.apis";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import { IPlatformInterface } from "./IPlatformInterface";
import {
  PLATFORM_ACTION_GET_AVAILABLE_PLAFTORM_URLS,
  PLATFORM_ACTION_GET_PLATFORMS,
  PLATFORM_ACTION_GET_SELECTED_PLATFORM,
  PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS,
  PLATFORM_MUTATION_SET_PLATFORMS,
  PLATFORM_MUTATION_SET_SELECTED_PLATFORM,
} from "./platformTypes";

export default {
  [PLATFORM_ACTION_GET_PLATFORMS]: async (
    context: ActionContext<IPlatformInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const platforms = await getPlatformsList(params);
      context.commit(PLATFORM_MUTATION_SET_PLATFORMS, platforms);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [PLATFORM_ACTION_GET_AVAILABLE_PLAFTORM_URLS]: async (
    context: ActionContext<IPlatformInterface, undefined>
  ) => {
    //Get the Users and store it
    try {
      const platformUrls = await getAvailablePlatformUrls();
      context.commit(
        PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS,
        platformUrls
      );
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [PLATFORM_ACTION_GET_SELECTED_PLATFORM]: async (
    context: ActionContext<IPlatformInterface, undefined>,
    platformId: string
  ) => {
    //Get the Users and store it
    try {
      const selectedPlatformDetails = await getPlatformDetails(platformId);
      context.commit(
        PLATFORM_MUTATION_SET_SELECTED_PLATFORM,
        selectedPlatformDetails
      );
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
