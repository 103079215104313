import {
  ENTITY_GETTERS_GET_AVAILABLE_DEVICE_GROUPS,
  ENTITY_GETTERS_GET_AVAILABLE_DEVICE_TYPES,
  ENTITY_GETTERS_GET_ENTITIES,
  ENTITY_GETTERS_GET_ENTITY_DETAILS,
} from "./entityTypes";
import { IEntityStoreInterface } from "./IEntityInterface";

export default {
  [ENTITY_GETTERS_GET_ENTITIES](state: IEntityStoreInterface) {
    return state.entities;
  },
  [ENTITY_GETTERS_GET_ENTITY_DETAILS](state: IEntityStoreInterface) {
    return state.selectedEntityDetails;
  },
  [ENTITY_GETTERS_GET_AVAILABLE_DEVICE_GROUPS](state: IEntityStoreInterface) {
    return state.availableDeviceGroups;
  },
  [ENTITY_GETTERS_GET_AVAILABLE_DEVICE_TYPES](state: IEntityStoreInterface) {
    return state.availableDeviceTypes;
  },
};
