// this store the routes for the customer-impacts pages using vue, it should look like the other routes file here in the same folder
//Interface
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

//Device Page
const ListAllOrphanDevices = () =>
  import("../views/Pages/CustomerImpact/ListAllOrphanDevices.vue");
const ListOrphanDevicesForBs = () =>
  import("../views/Pages/CustomerImpact/ListOrphanDevicesForBs.vue");

export const customerImpact: IRouteInterface = {
  path: "/customer-impact",
  component: PrimaryLayout,
  name: "Customer Impact",
  display: true,
  title: "Customer Impact",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/customer-impact/orphan-devices",
      name: "List Orphan Devices",
      component: ListAllOrphanDevices,
      display: true,
      title: "List Orphan Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Customer Impact",
        hideAppBar: true,
      },
    },
    {
      path: "/customer-impact/basestations",
      name: "Basestation Orphan Devices",
      component: ListOrphanDevicesForBs,
      display: true,
      title: "Basestation Orphan Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Customer Impact",
        hideAppBar: true,
      },
    },
  ],
};
