//Getters
export const AUTH_GETTER_USER_DETAILS = "AUTH_GETTER_USER_DETAILS";
export const AUTH_GETTER_JWT = "AUTH_GETTER_JWT";

//Mutations
export const AUTH_MUTATION_SET_USER_DETAILS = "AUTH_MUTATION_SET_USER_DETAILS";
export const AUTH_MUTATION_REMOVE_USER_DETAILS =
  "AUTH_MUTATION_REMOVE_USER_DETAILS";
export const AUTH_MUTATION_SET_JWT = "AUTH_MUTATION_SET_JWT";
export const AUTH_MUTATION_REMOVE_JWT = "AUTH_MUTATION_REMOVE_JWT";

//Actions
export const AUTH_ACTION_LOGIN_WITH_FIREBASE =
  "AUTH_ACTION_LOGIN_WITH_FIREBASE";
export const AUTH_ACTION_LOGIN_REDIRECT_CHECK =
  "AUTH_ACTION_LOGIN_REDIRECT_CHECK";
export const AUTH_ACTION_LOGOUT_FROM_FIREBASE =
  "AUTH_ACTION_LOGOUT_FROM_FIREBASE";
export const AUTH_ACTION_LOGIN_FROM_BACKEND = "AUTH_ACTION_LOGIN_FROM_BACKEND";
export const AUTH_ACTION_LOGOUT_FROM_BACKEND =
  "AUTH_ACTION_LOGOUT_FROM_BACKEND";
