import {
  IDeviceGroupInterface,
  IDeviceGroupStoreInterface,
  IDirectChidrenHierarchy,
} from "./IDeviceGroupInterface";
import {
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS,
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS,
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY,
} from "./deviceGroupTypes";

export default {
  [DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS](
    state: IDeviceGroupStoreInterface,
    deviceGroups: [IDeviceGroupInterface[], number]
  ) {
    state.deviceGroups = deviceGroups;
  },
  [DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS](
    state: IDeviceGroupStoreInterface,
    deviceGroupDetails: IDeviceGroupInterface
  ) {
    state.selectedDeviceGroupDetails = deviceGroupDetails;
  },
  [DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY](
    state: IDeviceGroupStoreInterface,
    directChildrenHierarchy: IDirectChidrenHierarchy
  ) {
    state.selectedDeviceGroupHierarchy = directChildrenHierarchy;
  },
};
