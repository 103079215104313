const defaultState = {
  organisations: [] as IOrganisationInterface[],
  permissions: [] as IPermissionsInterface[],
  roles: [] as IRolesInterface[],
  users: [[], 0],
  selectedUser: null,
} as IUserStoreInterface;
import getters from "./userGetters";
import actions from "./userActions";
import mutations from "./userMutations";
import {
  IOrganisationInterface,
  IPermissionsInterface,
  IRolesInterface,
  IUserStoreInterface,
} from "./IUserStoreInterface";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
