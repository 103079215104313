import { IPlatformStoreInterface } from "./IPlatformInterface";
import {
  PLATFORM_GETTER_GET_AVAILABLE_PLAFTORM_URLS,
  PLATFORM_GETTER_GET_PLATFORMS,
  PLATFORM_GETTER_GET_SELECTED_PLATFORM,
} from "./platformTypes";

export default {
  [PLATFORM_GETTER_GET_PLATFORMS](state: IPlatformStoreInterface) {
    return state.platforms;
  },
  [PLATFORM_GETTER_GET_SELECTED_PLATFORM](state: IPlatformStoreInterface) {
    return state.selectedPlatform;
  },
  [PLATFORM_GETTER_GET_AVAILABLE_PLAFTORM_URLS](
    state: IPlatformStoreInterface
  ) {
    return state.availablePlatformUrls;
  },
};
