import EmptyLayout from "../views/Layout/EmptyLayout.vue";

import { dashboard } from "./dashboard.routes";
import { devices } from "./devices.routes";
import { entities } from "./entity.routes";
import { admin } from "./admin.routes";
import { login } from "./login.routes";
import { setup } from "./setup.routes";
import { profile } from "./profile.routes";
import { suggestions } from "./suggestions.routes";
import { reports } from "./reports.routes";
import { deviceGroups } from "./deviceGroups.routes";
import { contracts } from "./contract.routes";
import { customerImpact } from "./customerImpact.routes";


//Reports

export default [
  dashboard,
  devices,
  deviceGroups,
  contracts,
  entities,
  customerImpact,
  setup,
  suggestions,
  admin,
  reports,
  ...login,
  profile,
];
