import { IDeviceGroupStoreInterface } from "./IDeviceGroupInterface";
import {
  DEVICE_GROUPS_GETTER_GET_DIRECT_CHILDREN_HIERARCHY,
  DEVICE_GROUPS_GETTER_GET_GROUPS,
  DEVICE_GROUPS_GETTER_GET_GROUP_DETAILS,
} from "./deviceGroupTypes";

export default {
  [DEVICE_GROUPS_GETTER_GET_GROUPS](state: IDeviceGroupStoreInterface) {
    return state.deviceGroups;
  },
  [DEVICE_GROUPS_GETTER_GET_GROUP_DETAILS](state: IDeviceGroupStoreInterface) {
    return state.selectedDeviceGroupDetails;
  },
  [DEVICE_GROUPS_GETTER_GET_DIRECT_CHILDREN_HIERARCHY](
    state: IDeviceGroupStoreInterface
  ) {
    return state.selectedDeviceGroupHierarchy;
  },
};
