import { IDeviceGroupInterface } from "../deviceGroup/IDeviceGroupInterface";
import {
  ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS,
  ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES,
  ENTITY_MUTATIONS_SET_ENTITY_DETAILS,
  ENTTY_MUTATIONS_SET_ENTITIES,
} from "./entityTypes";
import { IEntityInterface, IEntityStoreInterface } from "./IEntityInterface";

export default {
  [ENTTY_MUTATIONS_SET_ENTITIES](
    state: IEntityStoreInterface,
    entities: [IEntityInterface[], number]
  ) {
    state.entities = entities;
  },
  [ENTITY_MUTATIONS_SET_ENTITY_DETAILS](
    state: IEntityStoreInterface,
    selectedEntityDetails: IEntityInterface
  ) {
    state.selectedEntityDetails = selectedEntityDetails;
  },
  [ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS](
    state: IEntityStoreInterface,
    availableDeviceGroups: IDeviceGroupInterface[]
  ) {
    state.availableDeviceGroups = availableDeviceGroups;
  },
  [ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES](
    state: IEntityStoreInterface,
    availableDeviceTypes: any //TODO
  ) {
    state.availableDeviceTypes = availableDeviceTypes;
  },
};
