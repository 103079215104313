import {
  IPlatformInterface,
  IPlatformStoreInterface,
} from "./IPlatformInterface";
import getters from "./platformGetters";
import actions from "./platformActions";
import mutations from "./platformMutations";

const defaultState = {
  availablePlatformUrls: [] as string[],
  platforms: [[] as IPlatformInterface[], 0],
  selectedPlatform: null as unknown as IPlatformInterface,
} as IPlatformStoreInterface;

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
