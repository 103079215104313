import axios from "../../../axios";
import { ActionContext } from "vuex";
import {
  USER_ACTION_GET_ORGANISATIONS,
  USER_ACTION_GET_PERMISSIONS,
  USER_ACTION_GET_ROLES,
  USER_ACTION_GET_SELECTED_USER,
  USER_ACTION_GET_USERS,
  USER_MUTATIONS_SET_SELECTED_USER,
  USER_MUTATION_SET_ORGANISATIONS,
  USER_MUTATION_SET_PERMISSIONS,
  USER_MUTATION_SET_ROLES,
  USER_MUTATION_SET_USERS,
} from "./userTypes";
import {
  IOrganisationInterface,
  IPermissionsInterface,
  IRolesInterface,
  IUserStoreInterface,
} from "./IUserStoreInterface";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import { createUser, getUsers } from "../../../apis/user.apis";

export default {
  [USER_ACTION_GET_USERS]: async (
    context: ActionContext<IUserStoreInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const users = await getUsers(params);
      context.commit(USER_MUTATION_SET_USERS, { users });
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },

  [USER_ACTION_GET_ROLES]: async (
    context: ActionContext<IUserStoreInterface, undefined>
  ) => {
    //Get the Users and store it
    try {
      const { data: roles } = await axios.get<[IRolesInterface[], number]>(
        "/role/"
      );
      context.commit(USER_MUTATION_SET_ROLES, { roles });
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },

  [USER_ACTION_GET_PERMISSIONS]: async (
    context: ActionContext<IUserStoreInterface, undefined>
  ) => {
    //Get the Users and store it
    try {
      const { data: permissions } = await axios.get<
        [IPermissionsInterface[], number]
      >("/permission/");
      context.commit(USER_MUTATION_SET_PERMISSIONS, { permissions });
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },

  [USER_ACTION_GET_ORGANISATIONS]: async (
    context: ActionContext<IUserStoreInterface, undefined>
  ) => {
    //Get the Users and store it
    try {
      const { data: organisations } = await axios.get<
        [IOrganisationInterface[], number]
      >("/organisation/");
      context.commit(USER_MUTATION_SET_ORGANISATIONS, { organisations });
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },

  [USER_ACTION_GET_SELECTED_USER]: async (
    context: ActionContext<IUserStoreInterface, undefined>,
    email: string
  ) => {
    //Get the Users and store it
    try {
      const { data: selectedUser } = await axios.get<
        [IOrganisationInterface[], number]
      >(`/user/${email}`);
      context.commit(USER_MUTATIONS_SET_SELECTED_USER, { selectedUser });
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
