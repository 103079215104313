const defaultState = {
  drawer: null as unknown as boolean,
  sidebarColor: "success",
  sidebarTheme: "transparent",
  navbarFixed: false,
  showUniversalLoader: false,
} as IFrontendInterface;

import getters from "./frontendGetters";
import actions from "./frontendActions";
import mutations from "./frontendMutations";
import { IFrontendInterface } from "./IFrontendInterface";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
