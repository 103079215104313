import { ActionContext } from "vuex";
import {
  getContractDetails,
  getContractList,
  getExpiringContractsList,
} from "../../../apis/contract.apis";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import {
  CONTRACT_ACTION_GET_CONTRACT,
  CONTRACT_ACTION_GET_CONTRACT_DETAILS,
  CONTRACT_ACTION_GET_EXPIRING_CONTRACT,
  CONTRACT_MUTATION_SET_CONTRACT,
  CONTRACT_MUTATION_SET_CONTRACT_DETAILS,
} from "./contractTypes";
import { IContractInterface } from "./IContractInterface";

export default {
  [CONTRACT_ACTION_GET_CONTRACT]: async (
    context: ActionContext<IContractInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const contracts = await getContractList(params);
      context.commit(CONTRACT_MUTATION_SET_CONTRACT, contracts);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [CONTRACT_ACTION_GET_EXPIRING_CONTRACT]: async (
    context: ActionContext<IContractInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const contracts = await getExpiringContractsList(params);
      context.commit(CONTRACT_MUTATION_SET_CONTRACT, contracts);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [CONTRACT_ACTION_GET_CONTRACT_DETAILS]: async (
    context: ActionContext<IContractInterface, undefined>,
    sfDeviceId: string
  ) => {
    //Get the Users and store it
    try {
      const contractDetails = await getContractDetails(sfDeviceId);
      context.commit(CONTRACT_MUTATION_SET_CONTRACT_DETAILS, contractDetails);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
