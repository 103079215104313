import Vue from "vue";
import Vuex from "vuex";
import frontendModule from "./modules/frontend/frontend";
import authModule from "./modules/auth/auth";
import userModule from "./modules/user/user";
import deviceGroupModule from "./modules/deviceGroup/deviceGroup";
import entityModule from "./modules/entity/entity";
import platformsModule from "./modules/platforms/platforms";
import devicesModule from "./modules/device/device";
import contractModule from "./modules/contract/contract";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    frontend: frontendModule,
    auth: authModule,
    user: userModule,
    deviceGroups: deviceGroupModule,
    entities: entityModule,
    platforms: platformsModule,
    devices: devicesModule,
    contract: contractModule,
  },
});
