import { IEntityInterface, IEntityStoreInterface } from "./IEntityInterface";
import getters from "./entityGetters";
import actions from "./entityActions";
import mutations from "./entityMutations";
import { IDeviceGroupInterface } from "../deviceGroup/IDeviceGroupInterface";

const defaultState = {
  entities: [[] as IEntityInterface[], 0],
  selectedEntityDetails: null as unknown as IEntityInterface,
  availableDeviceGroups: [] as IDeviceGroupInterface[],
  availableDeviceTypes: [] as unknown, //TODO set IDeviceTypeInterface
} as IEntityStoreInterface;

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
