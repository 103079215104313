import {
  DEVICE_GETTER_GET_DEVICE,
  DEVICE_GETTER_GET_DEVICE_DETAILS,
} from "./deviceTypes";
import { IDeviceStoreInterface } from "./IDeviceInterface";

export default {
  [DEVICE_GETTER_GET_DEVICE](state: IDeviceStoreInterface) {
    return state.devices;
  },
  [DEVICE_GETTER_GET_DEVICE_DETAILS](state: IDeviceStoreInterface) {
    return state.selectedDeviceDetails;
  },
};
