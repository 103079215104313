import axios from "../axios";
import { IPlatformInterface } from "../store/modules/platforms/IPlatformInterface";

export async function getPlatformsList(
  params: any
): Promise<[IPlatformInterface[], number]> {
  const { data: platforms } = await axios.get<[IPlatformInterface[], number]>(
    "/platform-manager/",
    {
      params,
    }
  );
  return platforms;
}

export async function getPlatformDetails(platformId: string) {
  const { data: platformDetails } = await axios.get<{
    sfEntitieDetails: IPlatformInterface[];
  }>(`/platform-manager/${platformId}`);
  return platformDetails;
}

export async function getAvailablePlatformUrls() {
  const { data: availablePlatformUrls } = await axios.get<string[]>(
    `/platform-manager/get-all-available-platforms`
  );
  return availablePlatformUrls;
}

export async function isPlatformNameAvailable(name: string) {
  const { data: availability } = await axios.get<{
    platformNameAvailabile: Boolean;
    foundPlatform: { [key: string]: any };
  }>("/platform-manager/platform-name-availability", { params: { name } });
  return availability;
}

export async function createNewPlatform(platformName: string, baseUrl: string) {
  const { data: createdPlatform } = await axios.post<IPlatformInterface>(
    "/platform-manager",
    { platformName, baseUrl }
  );
  return createdPlatform;
}

export async function updateExistingPlatform(
  platformId: string,
  props: {
    platformName?: string;
    baseUrl?: string;
  }
) {
  const { data: platformEntity } = await axios.patch<IPlatformInterface>(
    `/platform-manager/${platformId}`,
    props
  );
  return platformEntity;
}

export async function deletePlatform(platformId: string) {
  const { data: deletedPlatform } = await axios.delete<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>(`/platform-manager/${platformId}`);
  return deletedPlatform;
}
