import { IUserInterface } from "../store/modules/user/IUserStoreInterface";

export function canUserAccessLoose(
  requiredPermissions: string[],
  userDetails: IUserInterface
) {
  if (!userDetails) return false;
  //Check if the user is admin
  if (userDetails.user_type == "ADMIN") return true;
  //Check if the user has one of the required access.
  const userPermissions = new Set(
    userDetails.permissions.map((permission) => permission.permission)
  );
  const foundAccess = requiredPermissions.find((permission) =>
    userPermissions.has(permission)
  );
  if (foundAccess) return true;
  return false;
}
