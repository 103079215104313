import {
  CONTRACT_MUTATION_SET_CONTRACT,
  CONTRACT_MUTATION_SET_CONTRACT_DETAILS,
} from "./contractTypes";
import {
  IContractInterface,
  IContractStoreInterface,
} from "./IContractInterface";

export default {
  [CONTRACT_MUTATION_SET_CONTRACT](
    state: IContractStoreInterface,
    contracts: [IContractInterface[], number]
  ) {
    state.contracts = contracts;
  },
  [CONTRACT_MUTATION_SET_CONTRACT_DETAILS](
    state: IContractStoreInterface,
    selectedContractDetails: IContractInterface
  ) {
    state.selectedContractDetails = selectedContractDetails;
  },
};
