import { IAuthState, IDecodedJWT } from "./IAuthInterfaces";

export default {
  setUserDetails(state: IAuthState, payload: { user: IDecodedJWT }) {
    state.user = payload.user;
  },
  removeUserDetails(state: IAuthState) {
    state.user = null;
  },
  setJWT(state: IAuthState, payload: { jwt: string }) {
    state.jwt = payload.jwt;
  },
  unsetJWT(state: IAuthState) {
    state.jwt = null;
  },
};
