//Interface
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

//Device Page
const ListAllDevices = () =>
  import("../views/Pages/Devices/ListAllDevices.vue");
const ListActivatedDevices = () =>
  import("../views/Pages/Devices/ListActivatedDevices.vue");
const TestFrameDevices = () =>
  import("../views/Pages/Devices/TestFrameDevices.vue");
const ExpiringDevices = () =>
  import("../views/Pages/Devices/ExpiringDevices.vue");
const BuyDevices = () => import("../views/Pages/Devices/BuyDevices.vue");
const FreeDevices = () => import("../views/Pages/Devices/FreeDevices.vue");
const TokenIssuesDevices = () =>
  import("../views/Pages/Devices/TokenIssuesDevices.vue");
const DeletedDevices = () =>
  import("../views/Pages/Devices/DeletedDevices.vue");
const RecycleBinDevices = () =>
  import("../views/Pages/Devices/RecycleBinDevices.vue");
const RoamingDevices = () =>
  import("../views/Pages/Devices/RoamingDevices.vue");
const TokenReplacedDevices = () =>
  import("../views/Pages/Devices/TokenReplacedDevices.vue");
const DeviceDetailsPage = () =>
  import("../views/Pages/Devices/DeviceDetails.vue");

export const devices: IRouteInterface = {
  path: "/devices",
  component: PrimaryLayout,
  name: "Devices",
  display: true,
  title: "Devices",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/devices/all",
      name: "List All Devices",
      component: ListAllDevices,
      display: true,
      title: "List All Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/activated",
      name: "Activated Devices",
      component: ListActivatedDevices,
      display: true,
      title: "Activated Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/test-frames",
      name: "Test Frame Devices",
      component: TestFrameDevices,
      display: true,
      title: "Test Frame Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/expiring",
      name: "Expiring Devices",
      component: ExpiringDevices,
      display: true,
      title: "Expiring Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/buy",
      name: "Buy Devices",
      component: BuyDevices,
      display: true,
      title: "Buy Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/free",
      name: "Free Trial Devices",
      component: FreeDevices,
      display: true,
      title: "Free Trial Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/token-issues",
      name: "Token Issue Devices",
      component: TokenIssuesDevices,
      display: true,
      title: "Token Issue Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/deleted",
      name: "Deleted Devices",
      component: DeletedDevices,
      display: true,
      title: "Deleted Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/recycle-bin",
      name: "Recycle Bin Devices",
      component: RecycleBinDevices,
      display: true,
      title: "Recycle Bin Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/roaming",
      name: "Roaming Devices",
      component: RoamingDevices,
      display: true,
      title: "Roaming Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/token-replaced",
      name: "Token Replaced Devices",
      component: TokenReplacedDevices,
      display: true,
      title: "Token Replaced Devices",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
    {
      path: "/devices/:sfSigfoxId",
      name: "Device Details",
      component: DeviceDetailsPage,
      display: false,
      title: "Device Details",
      icon: "fa fa-user",
      meta: {
        groupName: "Devices",
        hideAppBar: true,
      },
    },
  ],
};
