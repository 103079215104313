import axios from "../axios";
import { IDeviceInterface } from "../store/modules/device/IDeviceInterface";

export async function getDeviceList(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/",
    {
      params,
    }
  );
  return device;
}

export async function getActivatedDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/activated",
    {
      params,
    }
  );
  return device;
}

export async function getDevicesOntestFrames(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/test-frames",
    {
      params,
    }
  );
  return device;
}

export async function getExpiringDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/expiring",
    {
      params,
    }
  );
  return device;
}

export async function getBuyDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/buy",
    {
      params,
    }
  );
  return device;
}

export async function getFreeDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/free",
    {
      params,
    }
  );
  return device;
}

export async function getTokenIssueDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/token-issues",
    {
      params,
    }
  );
  return device;
}

export async function getDeletedDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/deleted",
    {
      params,
    }
  );
  return device;
}

export async function getRecycledDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/recycled",
    {
      params,
    }
  );
  return device;
}

export async function getRoamingDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/roaming",
    {
      params,
    }
  );
  return device;
}

export async function getTokenReplacedDevices(
  params: any
): Promise<[IDeviceInterface[], number]> {
  const { data: device } = await axios.get<[IDeviceInterface[], number]>(
    "/device-manager/token-replaced",
    {
      params,
    }
  );
  return device;
}

export async function getDeviceDetails(sfDeviceId: string) {
  const { data: deviceDetails } = await axios.get<IDeviceInterface>(
    `/device-manager/${sfDeviceId}`
  );
  return deviceDetails;
}
