const defaultState = {
  contracts: [[] as IContractInterface[], 0],
  selectedContractDetails: null as unknown as IContractInterface,
} as IContractStoreInterface;

import getters from "./contractGetters";
import actions from "./contractActions";
import mutations from "./contractMutations";
import {
  IContractInterface,
  IContractStoreInterface,
} from "./IContractInterface";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
