import moment from "moment-timezone";

export function enumToObjectArray(inputEnum: any): any {
  const returnObjectArray = [] as any;
  Object.keys(inputEnum)
    .filter((key) => isNaN(Number(key)))
    .forEach((key) => {
      returnObjectArray.push({
        value: inputEnum[key],
        key,
      });
    });
  return returnObjectArray;
}

export function getValueFromEnum(inputEnum: any, key: any) {
  return Object.keys(inputEnum).find((k) => inputEnum[k] === key) || key;
}

export function formatDateTime(unixTime: number) {
  if (isNaN(unixTime)) return unixTime;
  return moment.unix(unixTime).format("YYYY/MM/DD hh:mm A");
}

export function numberFormatter(num: number, digits = 2) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function capitalizeFirstLetter(letter: string) {
  return letter[0].toUpperCase() + letter.slice(1);
}

export function flattenObject(obj: any, parentKey = ""): Record<string, any> {
  return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const flattenedChild = flattenObject(value, newKey);
      Object.assign(acc, flattenedChild);
    } else {
      acc[newKey] = value;
    }

    return acc;
  }, {});
}

export function flattenObjectHybrid(
  obj: any,
  parentKey = ""
): Record<string, any> {
  return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      const flattenedChild = flattenObject(value, newKey); // The call to flatten object is intentional. Keep this.
      Object.assign(acc, flattenedChild);
    } else {
      acc[newKey] = value;
    }

    return acc;
  }, obj);
}
