import axios from "../axios";
import {
  IDeviceGroupInterface,
  IDirectChidrenHierarchy,
} from "../store/modules/deviceGroup/IDeviceGroupInterface";
import {
  EProgManGroupTypeEnum,
  ESigfoxRegionEnum,
} from "../structures/ECommonEnums";

export interface IStatsInterface {
  countOfDevices: number;
  countOfActiveDevices: number;
  countOfRoamableDevices: number;
  countOfRoamingDevices: number;
  countOfDeletedDevices: number;
  averageTimeTakenToActivate: string;
  averageTimeTakenByInactive: string;
  platforms: {
    platformId: string;
    platformName: string;
    baseUrl: string;
    calcActivatedDevices: number;
    calcRegisteredDevices: number;
  }[];
}

export async function getDeviceGroupsList(
  params: any
): Promise<[IDeviceGroupInterface[], number]> {
  const { data: deviceGroups } = await axios.get<
    [IDeviceGroupInterface[], number]
  >("/group-manager/", {
    params,
  });
  return deviceGroups;
}

export async function getDeviceGroupDetails(sfDeviceGroupId: string) {
  const { data: deviceGroupDetails } = await axios.get<{
    sfDeviceGroupDetails: IDeviceGroupInterface[];
    directChildrenHierarchy: IDirectChidrenHierarchy[];
  }>(`/group-manager/${sfDeviceGroupId}`);
  return deviceGroupDetails;
}

export async function getDeviceGroupLogs(
  sfDeviceGroupId: string,
  limit?: number,
  page?: number
) {
  const { data: deviceGroupLogs } = await axios.get(
    `/group-manager/${sfDeviceGroupId}/logs`,
    { params: { limit, page } }
  );
  return deviceGroupLogs;
}

export async function getDeviceGroupDevices(
  sfDeviceGroupId: string,
  params: any
) {
  const { data: deviceGroupDevices } = await axios.get(
    `/group-manager/${sfDeviceGroupId}/devices`,
    { params }
  );
  return deviceGroupDevices;
}

export async function getDeviceGroupDeviceTypes(
  sfDeviceGroupId: string,
  params: any
) {
  const { data: deviceGroupDeviceTypes } = await axios.get(
    `/group-manager/${sfDeviceGroupId}/device-types`,
    { params }
  );
  return deviceGroupDeviceTypes;
}

export async function groupsAndDevicesAffectedCount(
  sfDeviceGroupId: string,
  sameActualRegion: boolean
) {
  const { data: affectedGroupsAndDevicesCount } = await axios.get<{
    affectedGroupsCount: number;
    affectedDevicesCount: number;
  }>(`/group-manager/${sfDeviceGroupId}/affected-count`, {
    params: { "same-actual-region": sameActualRegion },
  });
  return affectedGroupsAndDevicesCount;
}
export async function updateGroupAndDevices(
  sfDeviceGroupId: string,
  sameActualRegion: boolean,
  newActualRegion: ESigfoxRegionEnum,
  newGroupType: EProgManGroupTypeEnum
) {
  const { data: updateGroupAndDevicesStatus } = await axios.get(
    `/group-manager/${sfDeviceGroupId}/update-group-and-devices`,
    {
      params: {
        "same-actual-region": sameActualRegion,
        "new-actual-region": newActualRegion,
        "new-group-type": newGroupType,
      },
    }
  );
  return updateGroupAndDevicesStatus;
}

export async function getThxEntitySuggestions(params: any): Promise<
  [
    {
      sfDeviceGroupId: String;
      sfDeviceGroupName: String;
      actualRegion: String;
      calcActivatedRegisteredDevices: String;
      progManGroupType: String;
    }[],
    number
  ]
> {
  const { data: thxEntitySuggestions } = await axios.get<
    [
      {
        sfDeviceGroupId: String;
        sfDeviceGroupName: String;
        actualRegion: String;
        calcActivatedRegisteredDevices: String;
        progManGroupType: String;
      }[],
      number
    ]
  >("/group-manager/get-thx-entity-suggestions", {
    params,
  });
  return thxEntitySuggestions;
}

export async function rejectThxEntitySuggestion(sfDeviceGroupId: string) {
  const { data: rejectedStatus } = await axios.patch<{
    status: "SUCCESS" | "ERROR";
    message: string;
  }>(`/group-manager/${sfDeviceGroupId}/reject-entity-suggestion`);
  return rejectedStatus;
}

export async function getDeviceGroupStats(sfDeviceGroupId: string) {
  const { data: deviceGroupDevices } = await axios.get<IStatsInterface>(
    `/group-manager/${sfDeviceGroupId}/stats`
  );
  return deviceGroupDevices;
}
