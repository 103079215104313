export interface IDecodedJWT {
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  permissions: string[];
  id: string;
  type: "ADMIN" | "USER";
  iat: number;
}

export interface IAuthState {
  jwt: null | string;
  user: null | IDecodedJWT;
}

export enum IFirebaseLoginType {
  EMAIL_PASSWORD,
  GOOGLE_POPUP,
  GOOGLE_REDIRECT,
}
