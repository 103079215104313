// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

import { PERMISSIONS_ACCESS } from "../constants";

import { allChildPermissions } from "../helpers/permission.helpers";

//Setup
const DeviceGroupsSetupPage = () =>
  import("../views/Pages/Setup/DeviceGroups/ListDeviceGroupsSetup.vue");
const DeviceGroupsDetailsSetupPage = () =>
  import("../views/Pages/Setup/DeviceGroups/DeviceGroupDetailsSetup.vue");
const EntitiesSetupPage = () =>
  import("../views/Pages/Setup/Entities/ListEntitiesSetup.vue");
const AddEntityPage = () =>
  import("../views/Pages/Setup/Entities/CreateNewEntity.vue");
const EntityDetailsSetupPage = () =>
  import("../views/Pages/Setup/Entities/EntityDetailsSetup.vue");
const PlatformSetupPage = () =>
  import("../views/Pages/Setup/Platforms/ListPlatformSetup.vue");
const AddPlatformPage = () =>
  import("../views/Pages/Setup/Platforms/CreateNewPlatform.vue");
const PlatformDetailsSetupPage = () =>
  import("../views/Pages/Setup/Platforms/PlatformDetailsSetup.vue");

export const setup: IRouteInterface = {
  path: "/setup",
  component: PrimaryLayout,
  name: "Setup",
  display: true,
  title: "Setup",
  icon: "fa fa-wrench",
  active: false,
  children: [
    {
      path: "/setup/device-groups",
      name: "Device Groups - Setup",
      component: DeviceGroupsSetupPage,
      display: true,
      title: "Device Groups",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/device-groups/:sfDeviceGroupId",
      name: "Device Group Details - Setup",
      component: DeviceGroupsDetailsSetupPage,
      display: false,
      title: "",
      icon: "fa fa-bolt",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/thx-entities",
      name: "Entities - Setup",
      component: EntitiesSetupPage,
      display: true,
      title: "Entities",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/thx-entities/add",
      name: "Add Entity",
      component: AddEntityPage,
      display: false,
      title: "Add Entity",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/thx-entities/:thxEntityId",
      name: "Entity Details - Setup",
      component: EntityDetailsSetupPage,
      display: false,
      title: "Entity Details",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/platforms",
      name: "Platforms - Setup",
      component: PlatformSetupPage,
      display: true,
      title: "Platforms",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/platforms/add",
      name: "Add Platform",
      component: AddPlatformPage,
      display: false,
      title: "Add Platform",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
    {
      path: "/setup/platforms/:platformId",
      name: "Platform Details - Setup",
      component: PlatformDetailsSetupPage,
      display: false,
      title: "Platform Details",
      icon: "fa fa-user",
      meta: {
        groupName: "Setup",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
      },
    },
  ],
};
