//Interface
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";

//Device Page
const ListAllContractsPage = () =>
  import("../views/Pages/Contract/ListAllContracts.vue");
const ExpiringContractsPage = () =>
  import("../views/Pages/Contract/ExpiringContracts.vue");

export const contracts: IRouteInterface = {
  path: "/contracts",
  component: PrimaryLayout,
  name: "Contracts",
  display: true,
  title: "Contracts",
  icon: "fa fa-microchip",
  active: false,
  children: [
    {
      path: "/contracts/all",
      name: "List All Contracts",
      component: ListAllContractsPage,
      display: true,
      title: "List All Contracts",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Contracts",
        hideAppBar: true,
      },
    },
    {
      path: "/contracts/expiring",
      name: "Expiring Contracts",
      component: ExpiringContractsPage,
      display: true,
      title: "Expiring Contracts",
      icon: "fa fa-microchip",
      active: false,
      meta: {
        groupName: "Contracts",
        hideAppBar: true,
      },
    },
  ],
};
