import {
  IUserStoreInterface,
  IOrganisationInterface,
  IPermissionsInterface,
  IRolesInterface,
  IUserInterface,
} from "./IUserStoreInterface";
import {
  USER_MUTATIONS_SET_SELECTED_USER,
  USER_MUTATION_SET_ORGANISATIONS,
  USER_MUTATION_SET_PERMISSIONS,
  USER_MUTATION_SET_ROLES,
  USER_MUTATION_SET_USERS,
} from "./userTypes";

export default {
  [USER_MUTATION_SET_USERS](
    state: IUserStoreInterface,
    payload: { users: [IUserInterface[], number] }
  ) {
    state.users = payload.users;
  },
  [USER_MUTATION_SET_PERMISSIONS](
    state: IUserStoreInterface,
    payload: { permissions: IPermissionsInterface[] }
  ) {
    state.permissions = payload.permissions;
  },
  [USER_MUTATION_SET_ROLES](
    state: IUserStoreInterface,
    payload: { roles: IRolesInterface[] }
  ) {
    state.roles = payload.roles;
  },
  [USER_MUTATION_SET_ORGANISATIONS](
    state: IUserStoreInterface,
    payload: { organisations: IOrganisationInterface[] }
  ) {
    state.organisations = payload.organisations;
  },

  [USER_MUTATIONS_SET_SELECTED_USER](
    state: IUserStoreInterface,
    payload: { selectedUser: IUserInterface }
  ) {
    state.selectedUser = payload.selectedUser;
  },
};
