// Interfaces
import { IRouteInterface } from "./IRouteInterface";

//layout
import PrimaryLayout from "../views/Layout/PrimaryLayout.vue";
import { PERMISSIONS_ACCESS } from "../constants";

import { allChildPermissions } from "../helpers/permission.helpers";

// Exports
const ListExportsPage = () =>
  import("../views/Pages/Reports/Exports/ListExports.vue");
const ActivatedAndRegisteredDevicesPage = () =>
  import("../views/Pages/Reports/ActivatedAndRegisteredDevices.vue");
const NotActivatedDevicesPage = () =>
  import("../views/Pages/Reports/NotActivatedDevices.vue");

export const reports: IRouteInterface = {
  path: "/reports",
  component: PrimaryLayout,
  name: "Reports",
  display: true,
  title: "Reports",
  icon: "fa fa-wrench",
  active: false,
  children: [
    {
      path: "/reports/activated-registered-devices",
      name: "Activated Registered Devices",
      component: ActivatedAndRegisteredDevicesPage,
      display: true,
      title: "Activated Registered Devices",
      icon: "fa fa-user",
      meta: {
        groupName: "Reports",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
    {
      path: "/reports/not-activated-devices",
      name: "Not Activated Devices",
      component: NotActivatedDevicesPage,
      display: true,
      title: "Not Activated Devices",
      icon: "fa fa-user",
      meta: {
        groupName: "Reports",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
    {
      path: "/reports/exports",
      name: "Export Jobs",
      component: ListExportsPage,
      display: true,
      title: "Export Jobs",
      icon: "fa fa-user",
      meta: {
        groupName: "Reports",
        permission: allChildPermissions(PERMISSIONS_ACCESS),
        hideAppBar: true,
      },
    },
  ],
};
