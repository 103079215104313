//The actions modules are use to communicate with the API server and store the data in the store
//The data is then passed over to the mutations
//The whole data to be stored is sent as payload

import { ActionContext } from "vuex";
import {
  getAvailableDeviceGroups,
  getAvailableDeviceTypes,
  getEntitiesList,
  getEntityDetails,
} from "../../../apis/entities.apis";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import {
  ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_GROUPS,
  ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_TYPES,
  ENTITY_ACTIONS_GET_ENTITIES,
  ENTITY_ACTIONS_GET_ENTITY_DETAILS,
  ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS,
  ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES,
  ENTITY_MUTATIONS_SET_ENTITY_DETAILS,
  ENTTY_MUTATIONS_SET_ENTITIES,
} from "./entityTypes";
import { IEntityInterface } from "./IEntityInterface";

export default {
  [ENTITY_ACTIONS_GET_ENTITIES]: async (
    context: ActionContext<IEntityInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const entities = await getEntitiesList(params);
      context.commit(ENTTY_MUTATIONS_SET_ENTITIES, entities);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [ENTITY_ACTIONS_GET_ENTITY_DETAILS]: async (
    context: ActionContext<IEntityInterface, undefined>,
    thxEntityId: string
  ) => {
    try {
      const entityDetails = await getEntityDetails(thxEntityId);
      context.commit(ENTITY_MUTATIONS_SET_ENTITY_DETAILS, entityDetails);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_GROUPS]: async (
    context: ActionContext<IEntityInterface, undefined>,
    params: { [key: string]: any }
  ) => {
    try {
      const availableDeviceGroups = await getAvailableDeviceGroups(params);
      context.commit(
        ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_GROUPS,
        availableDeviceGroups
      );
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [ENTITY_ACTIONS_GET_AVAILABLE_DEVICE_TYPES]: async (
    context: ActionContext<IEntityInterface, undefined>,
    params: { [key: string]: any }
  ) => {
    try {
      const availableDeviceTypes = await getAvailableDeviceTypes(params);
      context.commit(
        ENTITY_MUTATIONS_SET_AVAILABLE_DEVICE_TYPES,
        availableDeviceTypes
      );
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
