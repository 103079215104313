const defaultState = {
  deviceGroups: [[] as IDeviceGroupInterface[], 0],
  selectedDeviceGroup: null as unknown as IDeviceGroupInterface,
  selectedDeviceGroupDetails: null as unknown as IDeviceGroupInterface,
  selectedDeviceGroupHierarchy: [] as unknown as IDirectChidrenHierarchy,
} as IDeviceGroupStoreInterface;

import getters from "./deviceGroupGetters";
import actions from "./deviceGroupActions";
import mutations from "./deviceGroupMutations";
import {
  IDeviceGroupInterface,
  IDeviceGroupStoreInterface,
  IDirectChidrenHierarchy,
} from "./IDeviceGroupInterface";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
