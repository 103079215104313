import { ActionContext } from "vuex";
import {
  getDeviceGroupDetails,
  getDeviceGroupsList,
} from "../../../apis/deviceGroups.apis";
import { IFilterParams } from "../../../components/Filters/ISimpleFilters";
import { IDeviceGroupInterface } from "./IDeviceGroupInterface";
import {
  DEVICE_GROUPS_ACTION_GET_DEVICE_GROUPS,
  DEVICE_GROUPS_ACTION_GET_DEVICE_GROUP_DETALS,
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS,
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS,
  DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY,
} from "./deviceGroupTypes";

export default {
  [DEVICE_GROUPS_ACTION_GET_DEVICE_GROUPS]: async (
    context: ActionContext<IDeviceGroupInterface, undefined>,
    params: IFilterParams
  ) => {
    //Get the Users and store it
    try {
      const deviceGroups = await getDeviceGroupsList(params);
      context.commit(DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUPS, deviceGroups);
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
  [DEVICE_GROUPS_ACTION_GET_DEVICE_GROUP_DETALS]: async (
    context: ActionContext<IDeviceGroupInterface, undefined>,
    sfDeviceGroupId: string
  ) => {
    //Get the Users and store it
    try {
      const deviceGroupDetails = await getDeviceGroupDetails(sfDeviceGroupId);
      context.commit(
        DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DETALS,
        deviceGroupDetails.sfDeviceGroupDetails
      );
      context.commit(
        DEVICE_GROUPS_MUTATION_SET_DEVICE_GROUP_DIRET_CHILDREN_HIERARCHY,
        deviceGroupDetails.directChildrenHierarchy
      );
    } catch (err) {
      //TODO handle when the use does not have access to perform this action
      //? Show the warning or log the user out
    }
  },
};
