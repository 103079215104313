import { ActionContext } from "vuex";
import { IFrontendInterface } from "./IFrontendInterface";

export default {
  updateFrontendConfig: async (
    context: ActionContext<IFrontendInterface, undefined>,
    props: { [key: string]: any }
  ) => {
    context.commit("updateFrontendConfig", props);
  },
  showUniversalLoader: async (
    context: ActionContext<IFrontendInterface, undefined>,
    newState: boolean
  ) => {
    context.commit("showUniversalLoader", newState);
  },
};
