export function allChildPermissions(parentPermissionObject: any): string[] {
  //if this is a string then return the string
  if (typeof parentPermissionObject == "string") {
    return [parentPermissionObject];
  }

  return Object.keys(parentPermissionObject).reduce(
    (reducedPerm: any, key: string): string[] => {
      return [
        ...reducedPerm,
        ...allChildPermissions(parentPermissionObject[key]),
      ];
    },
    []
  );
}
