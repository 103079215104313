import {
  CONTRACT_GETTER_GET_CONTRACT,
  CONTRACT_GETTER_GET_CONTRACT_DETAILS,
} from "./contractTypes";
import { IContractStoreInterface } from "./IContractInterface";

export default {
  [CONTRACT_GETTER_GET_CONTRACT](state: IContractStoreInterface) {
    return state.contracts;
  },
  [CONTRACT_GETTER_GET_CONTRACT_DETAILS](state: IContractStoreInterface) {
    return state.selectedContractDetails;
  },
};
