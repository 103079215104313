//Getter
export const PLATFORM_GETTER_GET_PLATFORMS = "PLATFORM_GETTER_GET_PLATFORMS";
export const PLATFORM_GETTER_GET_SELECTED_PLATFORM =
  "PLATFORM_GETTER_GET_SELECTED_PLATFORM";
export const PLATFORM_GETTER_GET_AVAILABLE_PLAFTORM_URLS =
  "PLATFORM_GETTER_GET_AVAILABLE_PLAFTORM_URLS";

//Mutations
export const PLATFORM_MUTATION_SET_PLATFORMS =
  "PLATFORM_MUTATION_SET_PLATFORMS";
export const PLATFORM_MUTATION_SET_SELECTED_PLATFORM =
  "PLATFORM_MUTATION_SET_SELECTED_PLATFORM";
export const PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS =
  "PLATFORM_MUTATION_SET_AVAILABLE_PLAFTORM_URLS";

// Actions
export const PLATFORM_ACTION_GET_PLATFORMS = "PLATFORM_ACTION_GET_PLATFORMS";
export const PLATFORM_ACTION_GET_SELECTED_PLATFORM =
  "PLATFORM_ACTION_GET_SELECTED_PLATFORM";
export const PLATFORM_ACTION_GET_AVAILABLE_PLAFTORM_URLS =
  "PLATFORM_ACTION_GET_AVAILABLE_PLAFTORM_URLS";
