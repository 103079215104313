//Getters
export const CONTRACT_GETTER_GET_CONTRACT = "CONTRACT_GETTER_GET_CONTRACT";
export const CONTRACT_GETTER_GET_CONTRACT_DETAILS =
  "CONTRACT_GETTER_GET_CONTRACT_DETAILS";

//Mutations
export const CONTRACT_MUTATION_SET_CONTRACT = "CONTRACT_MUTATION_SET_CONTRACT";
export const CONTRACT_MUTATION_SET_CONTRACT_DETAILS =
  "CONTRACT_MUTATION_SET_CONTRACT_DETAILS";

//Actions
export const CONTRACT_ACTION_GET_CONTRACT = "CONTRACT_ACTION_GET_CONTRACT";
export const CONTRACT_ACTION_GET_EXPIRING_CONTRACT =
  "CONTRACT_ACTION_GET_EXPIRING_CONTRACT";
export const CONTRACT_ACTION_GET_CONTRACT_DETAILS =
  "CONTRACT_ACTION_GET_CONTRACT_DETAILS";
