const defaultState = {
  devices: [[] as IDeviceInterface[], 0],
  selectedDeviceDetails: null as unknown as IDeviceInterface,
} as IDeviceStoreInterface;

import getters from "./deviceGetters";
import actions from "./deviceActions";
import mutations from "./deviceMutations";
import { IDeviceInterface, IDeviceStoreInterface } from "./IDeviceInterface";

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
